import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  InstaPozBoardItem,
  InstaPozBoardItemPreview,
  InstaPozBoardResponse,
} from '@nai-libs/shared/data-access/src/lib/models/instapoz-board.models';
import { Observable, catchError, map, throwError } from 'rxjs';

import { APP_CONFIG } from '@nai-libs/app-config';
import { AppConfig } from '@nai-libs/data-access';

import { DateUtilsService } from '@nai-libs/utility';

@Injectable({
  providedIn: 'root',
})
export class InstaPozBoardService {
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private env: AppConfig,
    private dateUtils: DateUtilsService
  ) {}

  /**
   * Método para obtener los InstaPoz de interés de un cliente
   * @return Observable<NoticeBoardArticlePreview[]>
   */
  getInstaPozBoardItemPreviews(): Observable<InstaPozBoardItemPreview[]> {
    const paramsObj = {
      date: this.dateUtils.dateToYearMonthDayHyphen(new Date()),
    };

    const params = new URLSearchParams(paramsObj).toString();

    //TODO
    //change the url to obtain InstapozItems
    return (
      this.http
        // .get<InstaPozBoardResponse>(
        //   `${this.env.apiUrl}home/notifications/information-wall?${params}`
        .get<InstaPozBoardResponse>(
          `${this.env.apiUrl}home/notifications/instapoz`
        )
        .pipe(
          map((response) => {
            return response['result'].length > 0 ? response['result'] : [];
          }),
          catchError((err) => throwError(() => err))
        )
    );
  }

  getInstaPozBoardItem(id: string): Observable<InstaPozBoardItem> {
    const paramsObj = {
      id,
    };
    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<InstaPozBoardItem>(
        `${this.env.apiUrl}home/instapoz-photo?${params}`
      )
      .pipe(catchError((err) => throwError(() => err)));
  }
}
