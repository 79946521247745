import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { neuroReducer, NEURO_REDUCER_KEY } from './+state/neuro.reducer';
import { NeuroEffects } from './+state/neuro.effects';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(NEURO_REDUCER_KEY, neuroReducer),
    EffectsModule.forFeature([NeuroEffects])
  ]
})
export class NeuroDataAccessModule {}
