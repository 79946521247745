export interface ClockInPayload {
  NFC_id?: string;
  'client-phone': string;
  'aad-eid': string;
  hora_inicio: string;
  hora_fin: string;
}

export interface Intervention {
  'aad-eid'?: string;
  hora_inicio: string;
  hora_fin: string;
  instant: string;
  status: InterventionStatus;
}

export interface ClockInResponse {
  'success?': boolean;
}

export interface InterventionStatus {
  in: boolean;
  out: boolean;
  'time-in': string; //Instant
  'time-out': string; //Instant
}
