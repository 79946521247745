/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ConsultationActions } from '@nai-libs/consultation/data-access'; 
import { ConsultationSelectors } from '@nai-libs/consultation/data-access';

@Component({
  selector: 'nai-videoconsultation-success-dialog',
  template: `
    <div class="wrapper-fluid">
      <header>
        <span
          naiDynIcon
          [overrideColor]="false"
          [path]="'assets/icons/ok-check.svg'"
        ></span>

        <h1>¡Genial! Consulta asignada</h1>
        <p>Hemos guardado con éxito tu consulta</p>
      </header>
      <ng-container
        *ngIf="newVideoconsultation$ | async as newVideoconsultation"
      >
        <nai-summary-item
          [routerLink]="['/ayuda/consultas/nueva-videoconsulta']"
          *ngIf="newVideoconsultation['start-date']"
          title="Fecha y hora"
          [editable]="false"
        >
          <span>{{
            newVideoconsultation['start-date'] | date: 'EEEE d MMMM, h:mm'
          }}</span>
        </nai-summary-item>
        <nai-summary-item
          [routerLink]="['/ayuda/consultas/nueva-videoconsulta/motivo']"
          *ngIf="newVideoconsultation.reason"
          title="Motivo"
          [editable]="false"
        >
          <span>{{ newVideoconsultation.reason }}</span>
        </nai-summary-item>
        <nai-summary-item
          [routerLink]="['/ayuda/consultas/nueva-videoconsulta/datos']"
          *ngIf="
            newVideoconsultation['attendee-email'] &&
            newVideoconsultation['attendee-phone']
          "
          title="Asistente principal"
          [editable]="false"
        >
          <span>{{ newVideoconsultation['attendee-email'] }}</span>
          <span>{{ newVideoconsultation['attendee-phone'] }}</span>
        </nai-summary-item>
        <nai-summary-item
          *ngIf="newVideoconsultation.price"
          title="Precio"
          [editable]="false"
        >
          <span>{{ newVideoconsultation.price }}€</span>
        </nai-summary-item>
      </ng-container>
      <div class="actions-wrapper">
        <button
          class="button button--outline button--primary"
          (click)="exit()"
        >
          Salir
        </button>
      </div>
    </div>
  `,
  styleUrls: ['./new-videoconsultation-success-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewVideoconsultationSuccessDialogComponent {
  newVideoconsultation$ = this.store.select(
    ConsultationSelectors.selectNewVideoconsultation
  );

  constructor (
    private location: Location,
    private store: Store,
    private dialog: MatDialog,
    private router: Router
  ) {}

  handleBack() {
    this.location.historyGo(-1);
  }

  exit() {
    this.dialog.closeAll();
    this.router.navigate(['/ayuda']);
  }
  ngOnDestroy() {
    this.store.dispatch(ConsultationActions.resetNewVideoconsultation());
  }
}
