import { InjectionToken } from '@angular/core';
import { UserActions } from '@nai-libs/user/data-access';
import * as fromRouter from '@ngrx/router-store';
import {
  Action,
  ActionReducer,
  ActionReducerMap,
  MetaReducer,
} from '@ngrx/store';
import { environment } from '../../environments/environment';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface State {}

export const ROOT_REDUCERS = new InjectionToken<
  ActionReducerMap<State, Action>
>('Root reducers token', {
  factory: () => ({
    // [fromHelp.helpReducerKey]: fromHelp.reducer
  }),
});

export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state, action) => {
    const result = reducer(state, action);
    console.groupCollapsed(action.type);
    console.log('prev state', state);
    console.log('action', action);
    console.log('next state', result);
    console.groupEnd();

    return result;
  };
}

export function logout(reducer: ActionReducer<State>): ActionReducer<State> {
  return function (state, action) {
    // Clean state
    return reducer(
      action.type === UserActions.logout.type ? undefined : state,
      action
    );
  };
}

export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [logger, logout]
  : [logout];

export const { selectRouteData } = fromRouter.getSelectors();
