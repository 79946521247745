
export interface ForumSurveyOptions {
  id: string;
  value: string;
  status: string | null;
}

export interface ForumSurvey {
  id: string;
  title: string;
  options: ForumSurveyOptions[];
  'visualization-start': string;
  'visualization-end': string;
  answer: string | null;
  'closed?': boolean;
  'answers-count': string;
}

export interface ForumSurveyResponse {
  result: ForumSurvey[];
}
