import { timeslotText } from './schedule.models';

export interface Task {
  id: string;
  nombre: string;
  actividades: Activity[];
  frequency: string;
  'closed?': boolean;
  hora?: string;
  alertas?: {
    disfagia: boolean;
    medicacion: boolean;
  };
}
export interface TaskMoment {
  id: string;
  momento: timeslotText;
  horas: {
    inicio: string;
    fin: string;
  };
  tareas: Task[];
}

export interface TaskMapByServiceReceiver {
  [phone: string]: TaskMap[];
}

export interface TaskMap {
  'client-phone': string;
  date: {
    id: number;
    date: string;
    day: string; // DoW
  };
  'momentos-dia': TaskMoment[];
}

export interface TaskMapResponse {
  calendario: TaskMap;
}

export interface TaskMapMonthResponse {
  calendario: TaskMap[];
}

export type TaskStatsRange = 'day' | 'week' | 'month';
export interface TaskStatsItem {
  id: string;
  title: string;
  'to-do': number;
  done: number;
  image?: string;
}

export interface TaskStatsByCarer {
  id: string;
  email?: string;
  history: TaskStatsItem[];
}

export interface TaskStatsResponse {
  history: TaskStatsItem[];
}

export interface Activity {
  id: string;
  'raw-id': string;
  NANDA: string;
  nombre: string;
  'daily-identifier': string;
  momento: string;
  duracion: {
    cantidad?: string;
    unidad?: string;
  };
  hora?: string;
  frequency: string[];
  'done?': boolean;
  'alert?': boolean;
  preferencias?: string;
}

export interface TechnicalSheetStep {
  titulo: string;
  link: string;
}

export interface TechnicalSheet {
  description: string;
  pdf: string;
  duration: string;
  title: string;
  level: string;
  'sequential-format'?: TechnicalSheetStep[];
  methods: string;
  'task-code': string[];
  subtitle: string;
  code: string;
  materials: string;
  objective: string;
  'general-rules'?: {
    images: {
      link_1: string;
      link_2: string;
    };
    info: string;
  };
  recommendations: string;
}

export interface TechnicalSheetByActivity {
  [activityCode: string]: TechnicalSheet[];
}

export interface TechnicalSheetsResponse {
  'activity-cards': TechnicalSheet[];
}
