export interface RespiroAppointment {
  'free-space?': boolean;
  start: Date;
  end: Date;
  'includes-lunch?': boolean;
  'exceeds-monthly-hours?': boolean;
  selected?: boolean;
}
export interface RespiroAvailableAppointmentsByDay {
  [day: string]: RespiroAppointment[];
}

export interface RespiroAvailableAppointmentsByMonth {
  [month: string]: RespiroAvailableAppointmentsByDay;
}

export interface RespiroAvailableAppointmentsResponse {
  'available-spaces': RespiroAvailableAppointmentsByDay;
}

export interface RespiroConsumedHours {
  [month: string]: {
    availableHours: number;
    selectedHours: number;
  };
}
export interface RespiroConsumedHoursResponse {
  'used-hours': number;
}

export interface RespiroContactData {
  'service-data': {
    title: string;
    subtitle: string;
    desc1: string;
    button: string;
    desc2: string;
    'concat-data': {
      name: string;
      hour: string;
      mobile: string;
    }[];
  };
  'more-info': {
    title: string;
    subtitle: string;
    desc1: string;
    'concat-data': {
      name: string;
      hour: string;
      mobile: string;
    }[];
  };
}
