import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  Observable,
  catchError,
  forkJoin,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';

import { UserActions, UserSelectors } from '@nai-libs/user/data-access';
import { Store } from '@ngrx/store';

import * as RespiroActions from './respiro.actions';

import { NavController } from '@ionic/angular';
import { APP_CONFIG } from '@nai-libs/app-config';
import { AppConfig, RespiroConsumedHours } from '@nai-libs/data-access';
import { RespiroSelectors } from '../..';
import { RespiroService } from './respiro.service';

@Injectable()
export class RespiroEffects {
  constructor(
    private actions$: Actions,
    @Inject(APP_CONFIG) private env: AppConfig,
    private store: Store,
    private RespiroService: RespiroService,
    private navCtrl: NavController
  ) {}

  loadAvailableAppointments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RespiroActions.loadAvailableAppointments),
      withLatestFrom(
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([{ date }, serviceReceiver, selectedUser]) => {
        if (!serviceReceiver || !selectedUser) return of(UserActions.logout());
        return this.RespiroService.fetchAvailableAppointments(
          serviceReceiver,
          selectedUser,
          date
        ).pipe(
          map((availableAppointmentsByDay) =>
            RespiroActions.loadAvailableAppointmentsSuccess({
              availableAppointmentsByDay,
            })
          ),
          catchError(() => of(RespiroActions.loadAvailableAppointmentsError()))
        );
      })
    )
  );

  loadAvailableAppointmentsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RespiroActions.loadAvailableAppointmentsSuccess),
      map(({ availableAppointmentsByDay }) => {
        const dates: Date[] = [];
        for (const day of Object.keys(availableAppointmentsByDay)) {
          dates.push(new Date(day));
        }
        return RespiroActions.loadConsumedHours({ dates });
      })
    )
  );

  setFormMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RespiroActions.setFormMessage),
      switchMap((_) => {
        return of(RespiroActions.saveRespiroRequest());
      })
    )
  );

  saveRespiroRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RespiroActions.saveRespiroRequest),
      mergeMap((_) =>
        of(_)
          .pipe(
            withLatestFrom(
              this.store.select(RespiroSelectors.selectFormAppointments),
              this.store.select(RespiroSelectors.selectFormMessage),
              this.store.select(UserSelectors.selectServiceReceiver),
              this.store.select(UserSelectors.selectSelectedUser)
            )
          )
          .pipe(map((latest) => latest))
      ),
      switchMap(([, appointments, messages, serviceReceiver, selectedUser]) => {
        if (!serviceReceiver || !appointments || !selectedUser)
          return of(UserActions.logout());
        return this.RespiroService.saveRespiroRequest(
          appointments,
          serviceReceiver,
          selectedUser,
          messages
        ).pipe(
          map(() => RespiroActions.saveRespiroRequestSuccess()),
          catchError(() => of(RespiroActions.saveRespiroRequestError()))
        );
      })
    )
  );

  saveRespiroRequestSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RespiroActions.saveRespiroRequestSuccess),
        tap((_) => {
          this.navCtrl.navigateForward([
            'servicios',
            'respiro',
            'confirmacion',
          ]);
        })
      ),
    { dispatch: false }
  );

  loadContactData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RespiroActions.loadContactData),

      mergeMap((_) =>
        of(_)
          .pipe(
            withLatestFrom(
              this.store.select(UserSelectors.selectServiceReceiver),
              this.store.select(UserSelectors.selectSelectedUser),
              this.store.select(RespiroSelectors.selectContactData)
            )
          )
          .pipe(map((latest) => latest))
      ),
      switchMap(([, serviceReceiver, selectedUser, contactData]) => {
        if (!serviceReceiver || !selectedUser) return of(UserActions.logout());
        if (contactData)
          return of(RespiroActions.loadContactDataSuccess({ contactData }));
        return this.RespiroService.fetchContactData(
          serviceReceiver,
          selectedUser
        ).pipe(
          map((contactData) =>
            RespiroActions.loadContactDataSuccess({ contactData })
          ),
          catchError(() => of(RespiroActions.loadContactDataError()))
        );
      })
    )
  );

  loadConsumedHours$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RespiroActions.loadConsumedHours),
      withLatestFrom(
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([{ dates, reset }, serviceReceiver, selectedUser]) => {
        if (!serviceReceiver || !selectedUser) return of(UserActions.logout());
        const functions = [];
        for (const date of dates) {
          functions.push(() =>
            this.RespiroService.fetchConsumedHours(
              serviceReceiver,
              selectedUser,
              date
            )
          );
        }
        const execute = (fn: () => Observable<RespiroConsumedHours>) => fn();
        return forkJoin(functions.map(execute)).pipe(
          map((consumedHoursArray) => {
            const consumedHours: RespiroConsumedHours = {};
            for (const consumedHoursObject of consumedHoursArray) {
              for (const month of Object.keys(consumedHoursObject)) {
                consumedHours[month] = consumedHoursObject[month];
              }
            }
            return RespiroActions.loadConsumedHoursSuccess({
              consumedHours,
              reset,
            });
          }),
          catchError(() => of(RespiroActions.loadConsumedHoursError()))
        );
      })
    )
  );
}
