import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@nai-libs/app-config';
import { AppConfig, UserRoleIDMapping } from '@nai-libs/data-access';
import { UserSelectors } from '@nai-libs/user/data-access';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, combineLatest, map, take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CustomTranslateService {
  get currentLocale(): string {
    const currentLang = this.translateService.currentLang.split('-');
    return currentLang[currentLang.length - 1];
  }

  constructor(
    private store: Store,
    @Inject(APP_CONFIG) private env: AppConfig,
    private translateService: TranslateService
  ) {}

  getTranslateSelector(): Observable<string> {
    return combineLatest([
      this.store.select(UserSelectors.selectLoggedUserRole),
      this.store.select(UserSelectors.selectLoggedUserLanguage),
    ]).pipe(
      map(([role, language]) => {
        return `${this.env.appName}-${
          UserRoleIDMapping[role ?? 'Familiar referente']
        }-${language ?? 'es'}`;
      })
    );
  }

  translate() {
    this.getTranslateSelector()
      .pipe(take(1))
      .subscribe((selector) => {
        this.translateService.use(selector);
      });
  }
}
