export interface FormativeArticlesResponse {
  'formative-articles': FormativeArticle[];
}

export interface FormativeArticle {
  'NANDA-code': string;
  'article-code': string;
  'article-image': {
    title: string;
    link: string;
  };
  'article-title': string;
  'article-read?'?: boolean;
  'article-video-duration': null;
  'article-resumen': string;
  'publication-date'?: Date;
  'last-update'?: Date;
  'article-type': string;
  'compressibility-level': number;
  'read-time'?: string;
  'article-author': string;
  rating?: '1' | '2' | '3' | '4' | '5';
}

export interface FormativeArea {
  'NANDA-code': string;
  'NANDA-title': string;
  'NANDA-desc': string;
  'NANDA-image': string;
  'NANDA-blocked?'?: boolean;
  'NANDA-new?'?: boolean;
  'NANDA-total-articles': number;
  'NANDA-total-articles-read': number;
  'article-read?'?: boolean;
}

export interface FormativeAreaResponse {
  'formative-areas': FormativeArea[];
}

export interface SpecificFormativeArea {
  'formative-area': FormativeAreaInfo;
  'formative-area-articles': ArticleListItem[];
}

export interface ArticleListItem {
  'article-code': string;
  'article-image': {
    title: string;
    link: string;
  };
  'article-title': string;
  'article-author': string;
  'article-video-duration': string;
  'article-type': string;
  'formative-media': string;
  'article-read?'?: boolean;
}

export interface FormativeAreaInfo {
  'NANDA-code': string;
  'NANDA-title': string;
  'NANDA-desc': string;
  'NANDA-image': string;
}

export interface SpecificFormativeArticle {
  codigo: string;
  titulo: string;
  resumen: string;
  contenido: string;
  figura_cabecera?: {
    titulo: string;
    link: string;
  };
  actividad_documental: {
    'author-identifier': string;
    'author-image': {
      mobile: string;
      desktop: string;
    };
    'author-role': string;
  };
  objetivos_contenido: {
    obj_1: string;
  };
  bibliografia: Array<{
    titulo: string;
    link: string;
  }>;
  figuras: {
    leyenda_1: string;
    link_1: string;
  };
  fecha_revision: {
    ultima_revision: string;
    revisiones: number;
    periodicidad: string;
  };
  'formative-media': 'video' | 'text';
  'formative-media-link': string;
  'article-read?'?: boolean;
  'article-video-duration': null;
  'article-resumen': string;
  'publication-date'?: Date;
  'last-update'?: Date;
  'article-type': string;
  'compressibility-level': number;
  'read-time'?: string;
  'article-author': string;
  rating?: '1' | '2' | '3' | '4' | '5';
}
export interface SpecificArticle {
  'formative-article': SpecificFormativeArticle;
  'suggested-articles': Array<{
    'NANDA-code': string;
    'article-code': string;
    'article-image': {
      title: string;
      link: string;
    };
    'article-title': string;
    'formative-media': 'video' | 'text';
    'formative-media-link': string;
    'article-read?'?: boolean;
    'article-video-duration': null;
    'article-resumen': string;
    'publication-date'?: Date;
    'last-update'?: Date;
    'article-type': string;
    'compressibility-level': number;
    'read-time'?: string;
    'article-author': string;
    rating?: '1' | '2' | '3' | '4' | '5';
  }>;
}

export interface ArticleResource {
  ubikare?: string;
  type: 'Vídeo' | 'Audio' | 'Image' | 'Página Web';
  code: string;
  'article-code': string;
  title: string;
  link: string;
  duration: string;
  'background-image'?: string;
}

export interface ArticleResourceResponse {
  'article-resources': ArticleResource[];
}

export interface GlossaryWord {
  word: string;
  meanings: string[];
}

export interface GlossaryWordResponse {
  'glossary-word-requested': GlossaryWord;
}
