import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomValidators {
  static mustMatch(
    controlName: string,
    matchingControlName: string
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value[controlName] !== control.value[matchingControlName]) {
        return { matches: false };
      }
      return null;
    };
  }

  static hasRequirements(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value = control.value;
      if (value) {
        return CustomValidators.isRequiredLength(value) &&
          CustomValidators.hasRequiredChar(value) &&
          CustomValidators.hasRequiredDigit(value)
          ? null
          : { requirements: false };
      }
      return null;
    };
  }

  static isRequiredLength(value: string) {
    return value.length >= 4;
  }

  static hasRequiredChar(value: string) {
    return /[a-zA-Z]/.test(value);
  }

  static hasRequiredDigit(value: string) {
    return /\d/.test(value);
  }
}
