export interface NoticeBoardArticlePreview {
  id: string;
  'image-link': string;
  title: string;
}
export interface NoticeBoardResponse {
  result: NoticeBoardArticlePreview[];
}

export interface NoticeBoardArticle {
  description: string;
  date: string;
  hour: string;
  place: {
    title: string;
    link: string;
  };
  type: string;
  title: string;
  id: number;
  subtitle: string;
  'image-link': string;
  'visualization-end': string;
  'visualization-start': string;
  centre: 'Bizipoz Nerbioi';
  url?: {
    title: string;
    link: string;
  };
}

export interface NoticeBoardArticleMap {
  [id: string]: NoticeBoardArticle;
}
