export interface MarketplaceProduct {
  id: string;
  'image-link': string;
  title: string;

  type?: string;
  subtitle?: string;
  description?: string;
  'visualization-start'?: string;
  'visualization-end'?: string;
}

export interface MarketplaceProductsResult {
  result: MarketplaceProduct[];
}
