export interface HomeAccessibilityImage {
  title: string;
  link: string;
}
export interface HomeAccessibilityStatus {
  red: number;
  green: number;
}

export type HomeAccessibilityColor = 'red' | 'green' | null;

export interface HomeAccessibilityArea {
  elemento: string;
  area: string;
  id: string;
  problem: string;
  color: HomeAccessibilityColor;
  image: HomeAccessibilityImage;
}

export interface HomeAccessibility {
  title: string;
  slug: string;
  desc: string;
  image: HomeAccessibilityImage;
  'service-name': string;
  status: HomeAccessibilityStatus;
  areas: HomeAccessibilityArea[];
}

export interface HomeAccessibilityFormativeArticle {
  codigo: string;
  titulo: string;
  resumen: string;
  contenido: string;
  'contenido-pro'?: string;
  'contenido-dev'?: string;
  figura_cabecera?: {
    titulo: string;
    link: string;
  };
  actividad_documental: {
    'author-identifier': string;
    'author-image': {
      mobile: string;
      desktop: string;
    };
    'author-role': string;
  };
  objetivos_contenido: {
    obj_1: string;
  };
  bibliografia: Array<{
    titulo: string;
    link: string;
  }>;
  figuras: {
    leyenda_1: string;
    link_1: string;
  };
  fecha_revision: {
    ultima_revision: string;
    revisiones: number;
    periodicidad: string;
  };
}
export interface HomeAccessibilityResponse {
  results: HomeAccessibility[];
  'formative-article': HomeAccessibilityFormativeArticle;
  address: string;
  date: Date;
}

export interface HomeAccessibilityLastResponse {
  'last-vv-date': Date;
}

export interface AnswersDate {
  'answers-date': string;
}
export interface HomeAccessibilityNotificationResponse {
  VV: AnswersDate[];
}

export interface HomeAccessibilityNotificationSaveReadResponse {
  status: number;
  headers: {};
  body: null;
}
