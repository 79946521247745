import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { User } from '@nai-libs/data-access';
import {
  UserActions,
  UserSelectors,
  UserService,
} from '@nai-libs/user/data-access';
import { Store } from '@ngrx/store';
import {
  Observable,
  catchError,
  combineLatest,
  filter,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  isUserLoaded$ = this.store.select(UserSelectors.isUserLoaded);
  isServiceReceiverSelected$ = this.store.select(
    UserSelectors.isServiceReceiverSelected
  );

  constructor(
    private store: Store,
    private userService: UserService,
    private router: Router
  ) {}

  canActivate():
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.userService.getToken().pipe(
      switchMap((token) => {
        if (token) {
          return combineLatest([
            this.isUserLoaded$,
            this.isServiceReceiverSelected$,
          ]).pipe(
            tap(([isUserLoaded, isServiceReceiverSelected]) => {
              if (!isUserLoaded) {
                this.store.dispatch(
                  UserActions.loadUserData({ isLogin: false })
                );
              }
            }),
            filter(
              ([isUserLoaded, isServiceReceiverSelected]) =>
                !!isUserLoaded && !!isServiceReceiverSelected
            ),
            mergeMap(() => this.store.select(UserSelectors.selectUser)),
            map((user?: User) => {
              if (user && user['valid-token?']) {
                if (
                  user['logged-user']['terms-and-conditions-of-use'] &&
                  user['logged-user']['privacy-policy']
                ) {
                  return true;
                }
                return this.router.createUrlTree(['/legal']);
              }
              this.userService.logout();

              return false;
            })
          );
        }
        return of(this.router.createUrlTree(['/auth/login']));
      }),
      catchError((err) => {
        return of(this.router.createUrlTree(['/auth/login']));
      })
    );
  }
}
