export interface Faq {
  'FAQ-topic': string;
  'FAQ-keywords': string[];
  'FAQ-code': string;
  'FAQ-question': string;
  'FAQ-user-roles': string[];
  'FAQ-response': string;
  'FAQ-images': string[];
  'FAQ-resources': string[];
}

export interface FaqTopic {
  'FAQ-topic': string;
  'FAQ-list': Faq[];
}

export interface FaqResponse {
  FAQ: FaqTopic[];
}
