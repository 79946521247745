/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Inject, Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  Observable,
  catchError,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { concatMap, map } from 'rxjs/operators';

import * as UserActions from './user.actions';
import * as UserSelectors from './user.selectors';
import { SELECTED_USER_KEY, TOKEN_KEY, UserService } from './user.service';

import { Action, Store } from '@ngrx/store';

import { StorageService } from '@nai-libs/utility';

// Material
import { MatDialog } from '@angular/material/dialog';

import { NavController } from '@ionic/angular';
import { AlertService } from '@nai-libs/alert/features/alert-feature';
import { PasswordChangeSuccessPageComponent } from '@nai-libs/user/features/change-password-feature/src/lib/standalone/password-change-success-page/password-change-success-page.component';
import { PasswordResetSuccessPageComponent } from '@nai-libs/user/features/new-password-feature/src/lib/standalone/password-reset-success-page/password-reset-success-page.component';
import { PasswordRecoverySuccessPageComponent } from '@nai-libs/user/features/password-recovery-feature/src/lib/standalone/password-recovery-success-page/password-recovery-success-page.component';
import { CustomTranslateService } from '@nai-libs/utility';

import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { APP_CONFIG } from '@nai-libs/app-config';
import { ClockInActions } from '@nai-libs/clock-in/data-access/src';
import { PushNotificationActions } from '@nai-libs/push-notifications/data-access';
import { AppConfig, SelectedUser } from '@nai-libs/shared/data-access/src';

// Components

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private userService: UserService,
    private alert: AlertService,
    private dialog: MatDialog,
    private store: Store,
    private storage: StorageService,
    private navCtrl: NavController,
    private router: Router,
    private customTranslateService: CustomTranslateService,
    @Inject(APP_CONFIG) private env: AppConfig
  ) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.login),
      switchMap(({ email, password }) =>
        this.userService.login(email, password).pipe(
          map((token: string) => {
            this.storage.setItem(TOKEN_KEY, token);
            return UserActions.loginSuccess();
          }),
          catchError((_) => {
            return of(UserActions.loginFailure());
          })
        )
      )
    )
  );

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loginSuccess),
      map((_) => UserActions.loadUserData({ isLogin: true }))
    )
  );

  loadUserData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadUserData),
      switchMap(({ isLogin }) =>
        this.userService.fetchUserData().pipe(
          switchMap((user) => {
            if (isLogin) {
              if (
                user['logged-user']['naiha-fam-access'] === 'Cuidadora formal'
              ) {
                return [
                  UserActions.loadUserDataSuccess({
                    user,
                    redirection: 'selector-familiar',
                  }),
                ];
              }
              return [
                UserActions.loadUserDataSuccess({
                  user,
                  redirection: 'dashboard',
                }),
              ];
            }
            return [UserActions.loadUserDataSuccess({ user })];
          }),
          catchError((_) => of(UserActions.loadUserDataFailure()))
        )
      )
    )
  );

  loadUserDataSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadUserDataSuccess),
      withLatestFrom(this.storage.getItem(SELECTED_USER_KEY)),
      concatMap(([{ redirection, user }, selectedUser]) => {
        if (Capacitor.getPlatform() !== 'web') {
          this.store.dispatch(PushNotificationActions.requestPermission());
        }
        this.customTranslateService.translate();
        if (user) {
          const defaultUser: SelectedUser = {
            'user-id': user['logged-user']['e-mail'],
            'user-role': user['logged-user']['naiha-fam-access'],
            avatar: user['logged-user'].avatar,
            identifier: {
              name: user['logged-user'].identifier.name,
              'first-surname': user['logged-user'].identifier['first-surname'],
              'second-surname':
                user['logged-user'].identifier['second-surname'],
            },
          };

          const actions = [
            UserActions.setServiceReceiver({
              serviceReceiver: undefined,
              redirection,
            }),
            UserActions.setSelectedUser({
              selectedUser: (selectedUser as SelectedUser) ?? defaultUser,
            }),
          ];

          return of(...actions);
        } else {
          return of(UserActions.logout());
        }
      })
    )
  );

  loadUserDataFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.loadUserDataFailure),
        tap((_) => {
          this.navCtrl.navigateRoot('/auth/login');
        })
      ),
    { dispatch: false }
  );

  setServiceReceiver$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.setServiceReceiver),
      withLatestFrom(this.store.select(UserSelectors.selectUser)),
      switchMap(([{ serviceReceiver, redirection }, user]) => {
        if (!user) return of(UserActions.logout());

        if (!serviceReceiver) {
          return this.userService.getSavedServiceReceiver().pipe(
            switchMap((savedSr) => {
              if (savedSr && Array.isArray(user['service-receiver'])) {
                serviceReceiver = user['service-receiver'].find(
                  (s) => s['client-phone'] === savedSr
                );
              }
              if (!serviceReceiver) {
                if (Array.isArray(user['service-receiver'])) {
                  serviceReceiver = user['service-receiver'][0];
                } else {
                  serviceReceiver = user['service-receiver'];
                }
              }
              if (!serviceReceiver)
                return of(UserActions.setServiceReceiverFailure());

              return of(
                UserActions.setServiceReceiverSuccess({
                  serviceReceiver,
                  redirection,
                })
              );
            })
          );
        }
        return of(
          UserActions.setServiceReceiverSuccess({
            serviceReceiver,
            redirection,
          })
        );
      })
    )
  );

  setServiceReceiverSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.setServiceReceiverSuccess),
        withLatestFrom(this.store.select(UserSelectors.selectLoggedUserRole)),
        tap(([{ serviceReceiver, redirection }, userRole]) => {
          this.userService.persistServiceReceiver(serviceReceiver);
          if (
            this.env.appName === 'macrosad' &&
            userRole === 'Cuidadora formal'
          ) {
            this.store.dispatch(ClockInActions.loadActiveIntervention());
          }

          if (redirection) {
            this.navCtrl.navigateForward(redirection);
          }
        })
      ),
    { dispatch: false }
  );

  setServiceReceiverFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.setServiceReceiverFailure),
        tap((_) => {
          this.alert.showAlert('warning', 'Aún no tienes pacientes a tu cargo');
        })
      ),
    { dispatch: false }
  );

  loginFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.loginFailure),
        tap((_) => {
          this.alert.showAlert('error', 'Usuario o contraseña incorrectos.');
        })
      ),
    { dispatch: false }
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.logout),
        tap((_) => {
          this.userService.logout();
          // TODO this.reset.restoreInitialState();
          this.navCtrl.navigateRoot('/auth/login');
        })
      ),
    { dispatch: false }
  );

  sendPasswordRecoveryEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.sendPasswordRecoveryEmail),
      switchMap(({ email }) =>
        this.userService.sendPasswordRecoveryEmail(email).pipe(
          map((_) => UserActions.sendPasswordRecoveryEmailSuccess()),
          catchError((error) =>
            of(UserActions.sendPasswordRecoveryEmailFailure(error))
          )
        )
      )
    )
  );

  sendPasswordRecoveryEmailSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.sendPasswordRecoveryEmailSuccess),
        tap(() => {
          this.dialog.open(PasswordRecoverySuccessPageComponent, {
            width: '100%',
            height: '100%',
            maxHeight: '100%',
            maxWidth: '100%',
            panelClass: 'dialog-send-password-recovery-success',
            autoFocus: false,
          });
        })
      ),
    { dispatch: false }
  );

  saveNewPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.saveNewPassword),
      switchMap(({ email, password, tempPassword }) =>
        this.userService.saveNewPassword(email, password, tempPassword).pipe(
          map((_) => UserActions.saveNewPasswordSuccess()),
          catchError((error) => of(UserActions.saveNewPasswordFailure(error)))
        )
      )
    )
  );

  saveNewPasswordSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.saveNewPasswordSuccess),
        tap(() => {
          this.dialog.open(PasswordResetSuccessPageComponent, {
            width: '100%',
            height: '100%',
            maxHeight: '100%',
            maxWidth: '100%',
            panelClass: 'dialog-pass-success',
            autoFocus: false,
          });
        })
      ),
    { dispatch: false }
  );

  changePass$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.changePass),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver)
      ),
      switchMap(([{ newPass, oldPass }, user, serviceReceiver]) => {
        if (!user || !serviceReceiver) return of(UserActions.logout());
        return this.userService
          .changePassword(oldPass, newPass, user, serviceReceiver)
          .pipe(
            map((_) => UserActions.changePassSuccess()),
            catchError(() => of(UserActions.changePassFailure()))
          );
      })
    )
  );

  changePassSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.changePassSuccess),
        tap(() => {
          this.dialog.open(PasswordChangeSuccessPageComponent, {
            width: '100%',
            height: '100%',
            maxHeight: '100%',
            maxWidth: '100%',
            panelClass: 'dialog-pass-success',
            autoFocus: false,
          });
        })
      ),
    { dispatch: false }
  );

  acceptUsingTerms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.acceptUsingTerms),
      withLatestFrom(this.store.select(UserSelectors.selectUser)),
      switchMap(([, user]) => {
        if (!user) {
          return of(UserActions.logout());
        }
        return this.userService.acceptUsingTerms(user).pipe(
          map(() => UserActions.acceptUsingTermsSuccess()),
          catchError((error) =>
            of(UserActions.acceptUsingTermsFailure({ error }))
          )
        );
      })
    )
  );

  acceptUsingTermsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.acceptUsingTermsSuccess),
      switchMap(() => {
        return of(UserActions.loadUserData({ isLogin: true }));
      })
    )
  );

  acceptUsingTermsFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.acceptUsingTermsFailure),
      switchMap(() => {
        return of(UserActions.logout());
      })
    )
  );

  changeLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.changeLanguage),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([{ lang }, user, serviceReceiver, selectedUser]) => {
        if (!user || !serviceReceiver || !selectedUser)
          return of(UserActions.logout());

        return this.userService
          .changeLanguage(lang, user, serviceReceiver, selectedUser)
          .pipe(
            map((_) => UserActions.changeLanguageSuccess()),
            catchError((_) => of(UserActions.changeLanguageFailure()))
          );
      })
    )
  );

  changeLanguageSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.changeLanguageSuccess),
      switchMap(() => {
        return of(UserActions.loadUserData({ isLogin: false }));
      })
    )
  );

  changeAvatarColor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.changeAvatarColor),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([{ color, mode }, user, serviceReceiver, selectedUser]) => {
        if (!user || !serviceReceiver || !selectedUser)
          return of(UserActions.logout());

        return this.userService
          .changeAvatarColor(user, serviceReceiver, selectedUser, color, mode)
          .pipe(
            map((_) => UserActions.changeAvatarColorSuccess()),
            catchError((_) => of(UserActions.changeAvatarColorFailure()))
          );
      })
    )
  );

  changeAvatarColorSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.changeAvatarColorSuccess),
      switchMap(() => {
        this.navCtrl.back();
        return of(UserActions.loadUserData({ isLogin: false }));
      })
    )
  );

  changeSelectedUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.changeSelectedUser),
      withLatestFrom(this.store.select(UserSelectors.selectSelectedUser)),
      map(([{ selectedUser }, currentUser]) => {
        if (currentUser?.['user-id'] !== selectedUser['user-id']) {
          this.storage.setItem(SELECTED_USER_KEY, selectedUser);
          return UserActions.changeSelectedUserSuccess({ selectedUser });
        } else {
          return UserActions.changeSelectedUserFailure();
        }
      })
    )
  );

  changeSelectedUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.changeSelectedUserSuccess),
        tap((_) => {
          window.location.reload();
        })
      ),
    { dispatch: false }
  );
}
