import { RequestStatus } from '@nai-libs/data-access';
import {
  createReducer,
  on,
} from '@ngrx/store';
import * as NeuroActions from './neuro.actions';

export const NEURO_REDUCER_KEY = 'neuro';

export interface NeuroState {
  neuroLink?: string;
  neuroLoginStatus: RequestStatus;
}

const initialState: NeuroState = {
  neuroLoginStatus: { pending: false },
};

export const neuroReducer = createReducer(
  initialState,
  on(NeuroActions.neuroLogin, (state) => ({
    ...state,
    neuroLoginStatus: { pending: true },
  })),
  on(NeuroActions.neuroLoginSuccess, (state, { neuroLink }) => ({
    ...state,
    neuroLink,
    neuroLoginStatus: { pending: false },
  })),
  on(NeuroActions.neuroLoginFailure, (state) => ({
    ...state,
    neuroLoginStatus: { pending: false },
  }))
);
