import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@nai-libs/app-config';
import {
  AppConfig,
  NeuroLoginResponse,
  SelectedUser,
  ServiceReceiver,
  User,
} from '@nai-libs/data-access';
import { Observable, catchError, map, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NeuroService {
  constructor(
    @Inject(APP_CONFIG) private env: AppConfig,
    private http: HttpClient
  ) {}

  login(
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<string> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'familiar-email': user['logged-user']['e-mail'],
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<NeuroLoginResponse>(
        `${this.env.apiUrl}health/neuro-app/login?${params}`
      )
      .pipe(
        map((res) => res.link),
        catchError((err) => throwError(() => err))
      );
  }
}
