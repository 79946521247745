import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@nai-libs/shared/utility/src';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./tabs/tabs.module').then((m) => m.TabsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'auth/login',
    loadChildren: () =>
      import('@nai-libs/user/features/login-feature').then(
        (m) => m.LoginFeatureModule
      ),
  },
  {
    path: 'auth/recuperar-contrasena',
    loadChildren: () =>
      import('@nai-libs/user/features/password-recovery-feature').then(
        (m) => m.PasswordRecoveryFeatureModule
      ),
  },
  {
    path: 'legal',
    loadChildren: () =>
      import('@nai-libs/user/features/legal-feature').then(
        (m) => m.LegalFeatureModule
      ),
  },
  {
    path: 'perfil',
    loadChildren: () =>
      import('@nai-libs/user/features/profile-feature').then(
        (m) => m.ProfileFeatureModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'selector-usuario',
    loadChildren: () =>
      import('@nai-libs/user/features/switch-user-feture/src').then(
        (m) => m.SwitchUserFetureModule
      ),
    canActivate: [AuthGuard],
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
