import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';

import * as ArticleActions from './article.actions';
import * as ArticleSelectors from './article.selectors';

import { FormativeArea, FormativeArticle } from '@nai-libs/data-access';
import { Store } from '@ngrx/store';
import { ArticleService } from './article.service';

import { UserActions, UserSelectors } from '@nai-libs/user/data-access';

@Injectable()
export class ArticleEffects {
  constructor(
    private actions$: Actions,
    private articleService: ArticleService,
    private store: Store
  ) {}

  loadFormativeArticles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticleActions.loadFormativeArticles),
      mergeMap((_) =>
        of(_)
          .pipe(
            withLatestFrom(
              this.store.select(UserSelectors.selectServiceReceiver),
              this.store.select(UserSelectors.selectSelectedUser),
              this.store.select(ArticleSelectors.selectFormativeArticles)
            )
          )
          .pipe(map((latest) => latest))
      ),
      switchMap(([, serviceReceiver, selectedUser, articles]) => {
        if (!serviceReceiver || !selectedUser) {
          return of(UserActions.logout());
        }
        if (articles)
          return of(ArticleActions.loadFormativeArticlesSuccess({ articles }));
        return this.articleService
          .getFormativeArticles(serviceReceiver, selectedUser)
          .pipe(
            map((articles: FormativeArticle[]) =>
              ArticleActions.loadFormativeArticlesSuccess({ articles })
            ),
            catchError(() => of(ArticleActions.loadFormativeArticlesFailure()))
          );
      })
    )
  );

  loadFormativeAreas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticleActions.loadFormativeAreas),
      mergeMap((_) =>
        of(_)
          .pipe(
            withLatestFrom(
              this.store.select(UserSelectors.selectServiceReceiver),
              this.store.select(UserSelectors.selectSelectedUser),
              this.store.select(ArticleSelectors.selectFormativeAreas)
            )
          )
          .pipe(map((latest) => latest))
      ),
      switchMap(
        ([{ forceFetch }, serviceReceiver, selectedUser, formativeAreas]) => {
          if (!serviceReceiver || !selectedUser)
            return of(UserActions.logout());
          if (!forceFetch && formativeAreas)
            return of(
              ArticleActions.loadFormativeAreasSuccess({ formativeAreas })
            );

          return this.articleService
            .getFormativeAreas(serviceReceiver, selectedUser)
            .pipe(
              map((formativeAreas: FormativeArea[]) =>
                ArticleActions.loadFormativeAreasSuccess({ formativeAreas })
              ),
              catchError(() =>
                of(
                  ArticleActions.loadFormativeAreasFailure({
                    error: 'No se han podido recoger las areas formativas',
                  })
                )
              )
            );
        }
      )
    )
  );

  loadArea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticleActions.loadArea),
      mergeMap((_) =>
        of(_)
          .pipe(
            withLatestFrom(
              this.store.select(UserSelectors.selectServiceReceiver),
              this.store.select(UserSelectors.selectSelectedUser)
            )
          )
          .pipe(map((latest) => latest))
      ),
      switchMap(([{ id }, serviceReceiver, selectedUser]) => {
        if (!serviceReceiver || !selectedUser) {
          return of(UserActions.logout());
        }
        return this.articleService
          .getArea(id, serviceReceiver, selectedUser)
          .pipe(
            map((area) => ArticleActions.loadAreaSuccess({ area })),
            catchError(() => of(ArticleActions.loadAreaFailure()))
          );
      })
    )
  );

  loadArticle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticleActions.loadArticle),
      withLatestFrom(
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([{ id, area }, serviceReceiver, selectedUser]) => {
        if (!serviceReceiver || !selectedUser) {
          return of(UserActions.logout());
        }
        return this.articleService
          .getArticle(id, area, serviceReceiver, selectedUser)
          .pipe(
            map((article) => ArticleActions.loadArticleSuccess({ article })),
            catchError((error) => {
              return of(ArticleActions.loadArticleFailure());
            })
          );
      })
    )
  );

  loadArticleResources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticleActions.loadArticleResources),
      withLatestFrom(
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([{ id, area }, serviceReceiver, selectedUser]) => {
        if (!serviceReceiver || !selectedUser) {
          return of(UserActions.logout());
        }
        return this.articleService
          .getArticleResources(id, area, serviceReceiver, selectedUser)
          .pipe(
            map((resources) =>
              ArticleActions.loadArticleResourcesSuccess({ resources })
            ),
            catchError(() => of(ArticleActions.loadArticleResourcesFailure()))
          );
      })
    )
  );

  loadGlossaryWord$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticleActions.loadGlosaryWord),
      withLatestFrom(
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(
        ([{ NANDACode, articleCode, word }, serviceReceiver, selectedUser]) => {
          if (!serviceReceiver || !selectedUser) {
            return of(UserActions.logout());
          }
          return this.articleService
            .getGlossaryWord(
              NANDACode,
              articleCode,
              word,
              serviceReceiver,
              selectedUser
            )
            .pipe(
              map((glossaryWord) =>
                ArticleActions.loadGlosaryWordSuccess({ glossaryWord })
              ),
              catchError(() => of(ArticleActions.loadGlosaryWordFailure()))
            );
        }
      )
    )
  );

  markArticleAsRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticleActions.markArticleAsRead),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(
        ([{ NANDACode, articleCode }, user, serviceReceiver, selectedUser]) => {
          if (!user || !serviceReceiver || !selectedUser) {
            return of(UserActions.logout());
          }
          return this.articleService
            .markArticleAsRead(
              NANDACode,
              articleCode,
              user,
              serviceReceiver,
              selectedUser
            )
            .pipe(
              map((_) => ArticleActions.markArticleAsReadSuccess()),
              catchError((error) =>
                of(ArticleActions.markArticleAsReadFailure(error))
              )
            );
        }
      )
    )
  );

  rateArticle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticleActions.rateArticle),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(
        ([
          { NANDACode, articleCode, rating },
          user,
          serviceReceiver,
          selectedUser,
        ]) => {
          if (!user || !serviceReceiver || !selectedUser) {
            return of(UserActions.logout());
          }
          return this.articleService
            .rateArticle(
              NANDACode,
              articleCode,
              rating,
              user,
              serviceReceiver,
              selectedUser
            )
            .pipe(
              map((_) => ArticleActions.rateArticleSuccess({ rating })),
              catchError((error) =>
                of(ArticleActions.rateArticleFailure(error))
              )
            );
        }
      )
    )
  );
}
