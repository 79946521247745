import {
  InstaPozBoardItemMap,
  InstaPozBoardState,
} from '@nai-libs/shared/data-access/src/lib/models/instapoz-board.models';
import { createReducer, on } from '@ngrx/store';
import {
  loadInstaPozBoardItem,
  loadInstaPozBoardItemFailure,
  loadInstaPozBoardItemSuccess,
  loadInstaPozBoardPreviews,
  loadInstaPozBoardPreviewsFailure,
  loadInstaPozBoardPreviewsSuccess,
} from './instapoz.actions';

export const INSTAPOZ_BOARD_REDUCER_KEY = 'instapoz-board';

const initialState: InstaPozBoardState = {
  InstaPozBoardItemPreviewStatus: { pending: false },
  InstaPozBoardItemStatus: { pending: false },
};

export const InstaPozBoardReducer = createReducer(
  initialState,
  on(loadInstaPozBoardPreviews, (state) => ({
    ...state,
    InstaPozBoardItemPreviewStatus: { pending: true },
  })),
  on(
    loadInstaPozBoardPreviewsSuccess,
    (state, { InstaPozBoardItemPreviews }) => ({
      ...state,
      InstaPozBoardItemPreviews,
      InstaPozBoardItemPreviewStatus: { pending: false },
    })
  ),
  on(loadInstaPozBoardPreviewsFailure, (state) => ({
    ...state,
    InstaPozBoardItemPreviews: undefined,
    InstaPozBoardItemPreviewStatus: { pending: false },
  })),
  on(loadInstaPozBoardItem, (state) => ({
    ...state,
    InstaPozBoardItemStatus: { pending: true },
  })),
  on(loadInstaPozBoardItemSuccess, (state, { instaPozBoardItem }) => {
      let newInstaPozBoardItem: InstaPozBoardItemMap;
      if (state.InstaPozBoardItem) {
        newInstaPozBoardItem = { ...state.InstaPozBoardItem };
        newInstaPozBoardItem[instaPozBoardItem.id] = instaPozBoardItem;
      } else {
         newInstaPozBoardItem={
          [instaPozBoardItem.id]:instaPozBoardItem
      };
    }
    return {
      ...state,
      InstaPozBoardItem: newInstaPozBoardItem,
      InstaPozBoardItemStatus: { pending: false },
    };
  }),
  on(loadInstaPozBoardItemFailure, (state) => ({
    ...state,
    InstaPozBoardItem: undefined,
    InstaPozBoardItemStatus: { pending: false },
  }))
);
