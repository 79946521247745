import { Locale } from './translate.models';

export type AppName = 'etxetic' | 'macrosad' | 'naiha';
export interface AppConfig {
  appName: AppName;
  production: boolean;
  domain: string;
  baseUrl: string;
  apiUrl: string;
  availableLanguages: Locale[];
  features: Features;
}
interface Features {
  passwordRecovery: boolean;
  changePassword: boolean;
  changeLanguage: boolean;
  faq: boolean;
  videoconsultations: boolean;
}

export type FeatureKey = keyof Features;

export interface Tab {
  name: string;
  path: string;
}
