import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { INSTAPOZ_BOARD_REDUCER_KEY, InstaPozBoardReducer } from './+state/instapoz.reducer';
import { InstaPozBoardEffects } from './+state/instapoz.effects';

@NgModule({
  imports: [CommonModule,
            StoreModule.forFeature(INSTAPOZ_BOARD_REDUCER_KEY, InstaPozBoardReducer),
            EffectsModule.forFeature([InstaPozBoardEffects])
           ]
})
export class InstapozBoardDataAccessModule {}
