import {
    createFeatureSelector,
    createReducer,
    createSelector,
    on,
  } from '@ngrx/store';
  
  import {

    EventFilter,

  } from '@nai-libs/data-access';
  
  import * as ScheduleActions from './schedule.actions';
  
  export const SCHEDULE_REDUCER_KEY = 'schedule';
  
  export interface ScheduleState {
    selectedDay: Date;
    filter: EventFilter;
  }
  
  const initalState: ScheduleState = {
    selectedDay: new Date(),
    filter: 'all'
  }
  
  export const scheduleReducer = createReducer(
    initalState,

    on(ScheduleActions.setSelectedDay, (state, { selectedDay }) => ({
      ...state,
      selectedDay,
    })),
    on(ScheduleActions.setFilter, (state, { filter }) => ({
      ...state,
      filter,
    })),
    on(ScheduleActions.clearFilter, (state) => ({
      ...state,
      filter: 'all',
    })),
  );
  

  