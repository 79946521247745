import {
  InstaPozBoardItem,
  InstaPozBoardItemPreview,
} from '@nai-libs/shared/data-access/src/lib/models/instapoz-board.models';
import { createAction, props } from '@ngrx/store';

export const loadInstaPozBoardPreviews = createAction(
  '[InstaPozBoard] Load instapoz board previews'
);
export const loadInstaPozBoardPreviewsSuccess = createAction(
  '[InstaPozBoard] Load instapoz board previews success',
  props<{ InstaPozBoardItemPreviews: InstaPozBoardItemPreview[] }>()
);
export const loadInstaPozBoardPreviewsFailure = createAction(
  '[InstaPozBoard] Load instapoz board previews error'
);

export const loadInstaPozBoardItem = createAction(
  '[InstaPozBoard] Load instapoz especific item',
  props<{ id: string }>()
);

export const loadInstaPozBoardItemSuccess = createAction(
  '[InstaPozBoard] Load instapoz especific item success',
  props<{ instaPozBoardItem: InstaPozBoardItem }>()
);

export const loadInstaPozBoardItemFailure = createAction(
  '[InstaPozBoard] Load instapoz especific item failure'
);
