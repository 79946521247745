import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'nai-summary-item',
  template: `
    <div>
        <div class="font-overline title font-grey">{{ title }}</div>
        <div class="font-subhead-1 content">
            <ng-content></ng-content>
        </div>
    </div>
    <button *ngIf="editable" class="button button--clear button--icon">Editar</button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./summary-item.component.scss'],
})
export class SummaryItemComponent {
  @Output() formSubmit = new EventEmitter();
  @Input() title = '';
  // IMPROVE editable default false
  @Input() editable = true;
}
