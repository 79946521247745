import { Component } from '@angular/core';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { Capacitor } from '@capacitor/core';
import { Platform } from '@ionic/angular';
import { UserSelectors } from '@nai-libs/user/data-access/src';
import { Store } from '@ngrx/store';

@Component({
  selector: 'nai-root',
  template: `
    <ion-app [class.role-carer]="(userRole$ | async) === 'Cuidadora formal'">
      <ion-router-outlet></ion-router-outlet>
    </ion-app>
  `,
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  userRole$ = this.store.select(UserSelectors.selectLoggedUserRole);
  constructor(
    private store: Store,
    private screenOrientation: ScreenOrientation,
    private platform: Platform
  ) {
    if (Capacitor.getPlatform() !== 'web' && this.platform.is('mobile')) {
      this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
    }
  }
}
