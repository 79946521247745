import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';

import {
  InstaPozBoardItem,
  InstaPozBoardItemPreview,
} from '@nai-libs/shared/data-access/src/lib/models/instapoz-board.models';
import { Store } from '@ngrx/store';
import { InstaPozBoardActions } from '../..';
import {
  loadInstaPozBoardItem,
  loadInstaPozBoardPreviews,
  loadInstaPozBoardPreviewsFailure,
  loadInstaPozBoardPreviewsSuccess,
} from './instapoz.actions';
import * as InstaPozBoardSelectors from './instapoz.selectors';
import { InstaPozBoardService } from './instapoz.service';

@Injectable()
export class InstaPozBoardEffects {
  constructor(
    private actions$: Actions,
    private InstaPozBoardService: InstaPozBoardService,
    private store: Store
  ) {}

  loadInstaPozBoardPreviews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadInstaPozBoardPreviews),
      mergeMap((_) =>
        of(_)
          .pipe(
            withLatestFrom(
              this.store.select(
                InstaPozBoardSelectors.selectInstaPozBoardItemsPreviews
              )
            )
          )
          .pipe(map((latest) => latest))
      ),
      switchMap(([, InstaPozBoardItemPreviews]) => {
        if (InstaPozBoardItemPreviews)
          return of(
            loadInstaPozBoardPreviewsSuccess({
              InstaPozBoardItemPreviews,
            })
          );
        return this.InstaPozBoardService.getInstaPozBoardItemPreviews().pipe(
          map((InstaPozBoardItemPreviews: InstaPozBoardItemPreview[]) =>
            loadInstaPozBoardPreviewsSuccess({
              InstaPozBoardItemPreviews,
            })
          ),
          catchError(() => of(loadInstaPozBoardPreviewsFailure()))
        );
      })
    )
  );

  loadInstaPozBoardItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadInstaPozBoardItem),
      mergeMap((_) =>
        of(_)
          .pipe(
            withLatestFrom(
              this.store.select(
                InstaPozBoardSelectors.selectInstaPozBoardItemMap
              )
            )
          )
          .pipe(map((latest) => latest))
      ),
      switchMap(([{ id, type }, InstaPozBoardItemMap]) => {
        if (InstaPozBoardItemMap && id in InstaPozBoardItemMap) {
          return of(
            InstaPozBoardActions.loadInstaPozBoardItemSuccess({
              instaPozBoardItem: InstaPozBoardItemMap[id],
            })
          );
        }
        return this.InstaPozBoardService.getInstaPozBoardItem(id).pipe(
          map((instaPozBoardItem: InstaPozBoardItem) =>
            InstaPozBoardActions.loadInstaPozBoardItemSuccess({
              instaPozBoardItem,
            })
          ),
          catchError(() =>
            of(InstaPozBoardActions.loadInstaPozBoardItemFailure())
          )
        );
      })
    )
  );
}
