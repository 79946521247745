import { createSelector,createFeatureSelector } from "@ngrx/store";
import { INSTAPOZ_BOARD_REDUCER_KEY } from "./instapoz.reducer";
import { InstaPozBoardState } from "@nai-libs/shared/data-access/src/lib/models/instapoz-board.models";


export const selectInstaPozBoardState = createFeatureSelector<InstaPozBoardState>(
    INSTAPOZ_BOARD_REDUCER_KEY
  );

export const selectInstaPozBoardPreviewsPending = createSelector(
    selectInstaPozBoardState,
    (state) => state.InstaPozBoardItemPreviewStatus.pending
  );

export const selectInstaPozBoardItemsPreviews = createSelector(
    selectInstaPozBoardState,
    (state) => state.InstaPozBoardItemPreviews
  );

export const selectInstaPozBoardItem =  (id: string) => createSelector(
  selectInstaPozBoardState,
  (state) => state.InstaPozBoardItem?.[id]
)

export const selectInstaPozBoardItemMap  = createSelector(
  selectInstaPozBoardState,
  (state) => state.InstaPozBoardItem
)

export const selectInstaPozBoardItemStatus = createSelector(
  selectInstaPozBoardState,
  (state) => state.InstaPozBoardItemStatus
)
