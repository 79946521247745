export type AppointmentType =
  | 'videoconsultation'
  | 'face-to-face'
  | 'day-center'
  | 'office';
export type AppointmentStatus = 'passed' | 'active' | 'upcoming';
export type AppointmentMoment = 'night' | 'day';

export interface UpcomingAppointmentsResponse {
  'upcoming-appointments': Appointment[];
}

export interface Appointment {
  'intervention-eid': number;
  start: string;
  end: string;
  type: AppointmentType;
  description?: string;
  'attendee-state'?: 'accepted' | 'declined' | 'waiting';
  'attendee-eid'?: number;
  'attendees-count'?: number;
  'service-name': string;
  'prof-role': string;
  'prof-eid'?: number;
  'prof-identifier'?: string;
  'meet-link'?: string;
  reference?: 'nurse' | 'commercial' | 'mediator' | null;
  'prof-img-link'?: {
    desktop: string;
    mobile: string;
  };
  frequency?: string;
  done?: boolean;
  consult?: boolean;
  registered?: boolean;
  'prof-reference'?: 'nurse' | 'commercial' | 'mediator' | null;
  place?: {
    link: string;
    title: string;
  };
}
export interface AppointmentsByMonth {
  month: string;
  year: string;
  'scheduled-appointments': Appointment[];
}

export interface VideoCallsResponse {
  videoconsultations: Appointment[];
}
