import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@nai-libs/app-config';
import {
  AppConfig,
  ClockInPayload,
  ClockInResponse,
  Intervention,
  InterventionStatus,
} from '@nai-libs/data-access';
import { Observable, catchError, map, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClockInService {
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private env: AppConfig
  ) {}

  fetchInterventionStatus(
    clientPhone: string,
    intervention: Intervention
  ): Observable<InterventionStatus> {
    const paramsObj = {
      'client-phone': clientPhone,
      'aad-eid': intervention['aad-eid'] ?? '',
      hora_inicio: intervention.hora_inicio,
      hora_fin: intervention.hora_fin,
    };
    const params = new URLSearchParams(paramsObj).toString();

    // return of({ in: true, out: false });
    return this.http
      .get<{ fichaje: InterventionStatus }>(
        `${this.env.apiUrl}familiar-profile/clock-in-done?${params}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .pipe(map((response) => response.fichaje));
  }

  clockIn(payload: ClockInPayload): Observable<ClockInResponse> {
    return this.http
      .post<ClockInResponse>(
        `${this.env.apiUrl}familiar-profile/clock-in`,
        {
          ...payload,
          NFC_id: payload.NFC_id ? payload.NFC_id : 'manual',
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .pipe(catchError((err) => throwError(() => err)));
  }

  clockOut(payload: ClockInPayload): Observable<ClockInResponse> {
    return this.http
      .post<ClockInResponse>(
        `${this.env.apiUrl}familiar-profile/clock-out`,
        {
          ...payload,
          NFC_id: payload.NFC_id ? payload.NFC_id : 'manual',
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .pipe(catchError((err) => throwError(() => err)));
  }
}
